define(function(require) {
    const BaseView = require('app/Base.view');
    const BaseTemplate = require('dcViews/Base.template.html');
    const dc = require('dc');
    const d3 = dc.d3;
    const _ = require('underscore');
    const Backbone = require('backbone');
    const moment = require('moment');
    const ManualRangeInputs = require('dcViews/ManualRangeInputs.view');

    require('./Base.css');
    require('./LotameLineChart.js');

    const baseChart = BaseView.extend({
        baseTemplate: _.template(BaseTemplate),

        defaults: function() {
            return {
                group: undefined
            };
        },

        initialize: function(options) {
            const self = this;

            window.addEventListener(
                'resize',
                _.debounce(function() {
                    let hasBeenUpdated = false;
                    if (!self.$el) {
                        return;
                    }
                    _.each(dc.chartRegistry.list(self.group), function(chart) {
                        try {
                            // Since we can add to the chart registry easily,
                            // each entry may not fully implement the interface
                            if (_.isFunction(chart.anchorName)) {
                                const newWidth = self
                                    .$(chart.anchorName())
                                    .parent()
                                    .width();
                                if (self.hasValue(newWidth) && newWidth != chart.width()) {
                                    hasBeenUpdated = true;
                                    chart.width(newWidth);
                                }
                            }
                        } catch (err) {
                            console.error(`Failed to update width${err}`);
                        }
                    });

                    if (hasBeenUpdated) {
                        dc.redrawAll(self.group);
                    }
                }, 500),
                false
            );
        },

        events: {
            'click .reset': 'onReset',
            'click .reset-all': 'resetAll',
            'click .reapply': 'onReapply',
            'click .remove-filter': 'handleRemoveFilter'
        },

        lookupInRegistry: function($chart) {
            const self = this;
            return _.filter(dc.chartRegistry.list(self.group), function(chart) {
                try {
                    // Since we can add to the chart registry easily,
                    // each entry may not fully implement the interface
                    if (_.isFunction(chart.anchorName)) {
                        // Lookup the chart in the registry and reset things
                        if ($chart.attr('id') == chart.anchorName()) {
                            return chart;
                        } else if ($chart.attr('class').indexOf(chart.anchorName().replace('.', '')) !== -1) {
                            return chart;
                        }
                    }
                } catch (err) {
                    console.error(`Failed to lookup chart${err}`);
                }
            });
        },

        onReset: function(e) {
            const self = this;
            const $el = self.$(e.currentTarget);
            const $chart = $el.parents('.dc-chart');
            _.each(self.lookupInRegistry($chart), function(chart) {
                chart.filterAll(self.group);
                self.toggleReapplyFilter(chart, $chart, true);
                dc.redrawAll(self.group);
            });
        },

        resetAll: function() {
            const self = this;
            dc.filterAll(self.group);
            self.applyAllDefaultFilters();
            dc.renderAll(self.group);
        },

        onReapply: function(e) {
            const self = this;
            const $el = self.$(e.currentTarget);
            const $chart = $el.parents('.dc-chart');
            _.each(self.lookupInRegistry($chart), function(chart) {
                self.toggleReapplyFilter(chart, $chart, false);
            });
        },

        handleRemoveFilter: function(e) {
            const $el = this.$(e.currentTarget);
            const filter = $el.data('filter');
            const chartId = $el.data('chartid');
            const foundChart = _.find(dc.chartRegistry.list(this.group), function(chart) {
                if (_.isFunction(chart.chartID)) {
                    return chart.chartID() == chartId;
                }
                return false;
            });
            const newFilters = _.reject(foundChart.filters(), function(currentFilter) {
                if (filter === currentFilter) {
                    return true;
                } else if (_.isArray(currentFilter) && filter == currentFilter[0]) {
                    // For Complex keys
                    return true;
                }
            });
            if (newFilters.length > 0) {
                foundChart.replaceFilter(newFilters);
            } else {
                foundChart.filter(null);
            }
        },

        // For charts that use the showReapplyFilter option, show/hide the link.
        // This also sets the clear filter link display - if it's just invisible, it still occupies space.
        toggleReapplyFilter: function(chartObj, $chart, visible, applyDefault = true) {
            if (!_.isUndefined(chartObj.showReapplyFilter) && chartObj.showReapplyFilter) {
                if (visible) {
                    $chart.find('.reapply').css({
                        visibility: '',
                        display: ''
                    });
                    $chart.find('.reset').css('display', 'none');
                    $chart.find('.text-filter-min').hide();
                    $chart.find('.text-filter-max').hide();
                } else {
                    $chart.find('.reapply').css({
                        visibility: 'hidden',
                        display: 'none'
                    });
                    $chart.find('.reset').css('display', '');
                    if (applyDefault) {
                        this.applyDefaultFilters(chartObj);
                    }
                    $chart.find('.text-filter-min').show();
                    $chart.find('.text-filter-max').show();
                }
            }
        },

        renderCustomBrushes: function(chart, handleHeight, useCircleHandles) {
            // Adapted from dc 3.0.9
            chart.redrawBrush = function(brushSelection, doTransition) {
                const _brushOn = chart.brushOn();
                const _brush = chart.brush();
                const _gBrush = chart.gBrush();
                const _resizing = chart.resizing();
                const CUSTOM_BRUSH_HANDLE_CLASS = 'custom-brush-handle';
                const _x = chart.x();

                if (_brushOn && _gBrush) {
                    if (_resizing) {
                        chart.setBrushExtents(doTransition);
                    }

                    if (!brushSelection) {
                        _gBrush.call(_brush.move, null);

                        _gBrush.selectAll(`path.${CUSTOM_BRUSH_HANDLE_CLASS}`).attr('display', 'none');
                    } else {
                        const scaledSelection = [_x(brushSelection[0]), _x(brushSelection[1])];

                        const gBrush = dc.optionalTransition(
                            doTransition,
                            chart.transitionDuration(),
                            chart.transitionDelay()
                        )(_gBrush);

                        gBrush.call(_brush.move, scaledSelection);

                        gBrush
                            .selectAll(`path.${CUSTOM_BRUSH_HANDLE_CLASS}`)
                            .attr('display', null)
                            .attr('transform', function(d, i) {
                                return `translate(${_x(brushSelection[i])},${chart.effectiveHeight() / 2.0})`;
                            })
                            .attr('d', chart.resizeHandlePath);
                    }
                }
                chart.fadeDeselectedArea(brushSelection);
            };

            // Adapted from dc 3.0.9
            chart.resizeHandlePath = function(d) {
                d = d.type;
                const e = +(d === 'e'),
                    x = e ? 1 : -1,
                    y = handleHeight;

                if (useCircleHandles) {
                    const pathTemplate = _.template(
                        'M <%= cx %>, <%= cy %> ' +
                            'm <%= -1 * r %> , 0 ' +
                            'a <%= r %> ,<%= r %> 0 1, 0 <%= r * 2 %>,0 ' +
                            'a <%= r %>, <%= r %> 0 1, 0 -<%= r * 2 %>,0'
                    );
                    return pathTemplate({
                        cx: x,
                        cy: y * 1.25,
                        r: handleHeight / 2
                    });
                } else {
                    const path =
                        `M${0.5 * x},${
                            -y // Move to 0.5x, -y (topmost point)
                        } A6,6 0 0 ${e} ${6.5 * x},${
                            -y + 6 // Arc around toward short side
                        } V${
                            y - 6 // vertical line from end of arc to y-6 (short side)
                        } A6,6 0 0 ${e} ${0.5 * x},${
                            y // Bottom arc around to long side
                        } Z` + // Close by drawing long side.
                        ` M${2.5 * x},${
                            -y + 8 // This starts the inside lines.
                        } V${y - 8} M${4.5 * x},${-y + 8} V${y - 8}`;

                    return path;
                }
            };

            if (useCircleHandles) {
                this.$(chart.anchor()).addClass('circle-brush');
                chart.xAxis().tickPadding(10);
            }
        },

        buildHistogram: function(el, dimension, group, opts) {
            const self = this;
            const width = self.$(el).width();
            const chart = dc.barChart(el, self.group);
            const options = _.extend(
                {},
                {
                    useCircleHandles: false,
                    binwidth: undefined,
                    xAxisPadding: opts.binwidth,
                    applyInitialFilter: function() {},
                    turnOnControls: true,
                    getTooltip: function(d) {
                        return d;
                    },
                    roundYaxis: false,
                    showTooltip: true,
                    showReapplyFilter: false,
                    showInputBoxes: false
                },
                opts
            );

            chart
                .width(width)
                .x(d3.scaleLinear())
                .elasticX(true)
                .elasticY(true)
                .centerBar(true)
                .gap(1)
                .options({
                    turnOnControls: options.turnOnControls
                })
                .controlsUseVisibility(true)
                .dimension(dimension)
                .group(group);

            if (options.binwidth) {
                chart.xUnits(dc.units.fp.precision(options.binwidth));
            }

            if (options.xAxisPadding) {
                chart.xAxisPadding(options.xAxisPadding);
            }

            self.renderCustomBrushes(chart, options.handleHeight, options.useCircleHandles);

            if (options.roundYaxis) {
                chart.yAxis().tickFormat(d3.format('d'));
                chart.on('preRedraw', function() {
                    const max = chart.group().top(1)[0].value;
                    chart.yAxis().tickValues(null);
                    if (max < 10) {
                        // Prevent decimals
                        chart.yAxis().ticks(max, 's');
                    } else {
                        // Let the default handling take over
                        chart.yAxis().ticks(5, 's');
                    }
                });
            }
            self.$(chart.anchor()).addClass('histogram-chart');
            chart.applyInitialFilter = options.applyInitialFilter.bind(chart);

            if (options.showTooltip) {
                const tooltip = d3.select('.base-tooltip').node()
                    ? d3.select('.base-tooltip')
                    : d3
                          .select('body')
                          .append('div')
                          .attr('class', 'base-tooltip');
                chart.on('pretransition.add-tip', function(chart) {
                    chart
                        .selectAll('rect.bar')
                        .on('mouseover', function(d) {
                            const parentElement = d3
                                .select(d3.event.currentTarget)
                                .node()
                                .getBoundingClientRect();

                            const content = options.getTooltip.apply(self, [d]);
                            tooltip.style('display', 'inline-block').html(content);

                            const x = parentElement.x + parentElement.width / 2;
                            const left = x - tooltip.node().getBoundingClientRect().width / 2;
                            const top =
                                parentElement.top - tooltip.node().getBoundingClientRect().height + window.scrollY - 10;
                            tooltip.style('left', `${left}px`);
                            tooltip.style('top', `${top}px`);
                        })
                        .on('mouseout', function() {
                            tooltip.style('display', 'none');
                        });
                });
            }

            // Add special handling for the filters
            const template = _.template(
                /* template*/ `<span class="badge badge-filter"><%= text %><i class="remove-filter lotacon lotacon-remove-circle" data-filter="<%= filter %>" data-chartid="<%= chartId %>"></i></span>`
            );
            chart.turnOnControls = function() {
                const attribute = this.controlsUseVisibility() ? 'visibility' : 'display';
                this.selectAll('.reset').style(attribute, null);

                const filterPrinterFn = this.filterPrinter();
                const filtersToAdd = filterPrinterFn(this.filters());
                const html = _.map(_.isArray(filtersToAdd) ? filtersToAdd : [filtersToAdd], function(filter) {
                    return template({
                        text: filter,
                        filter: filter,
                        chartId: chart.chartID()
                    });
                });
                this.selectAll('.filter')
                    .style(attribute, null)
                    .html(html.join(''));

                return this;
            };

            chart.showReapplyFilter = options.showReapplyFilter;
            chart.model = new Backbone.Model({
                rangeMinimum: undefined,
                rangeMaximum: undefined,
                label: options.label
            });
            if (options.showInputBoxes) {
                this.listenTo(chart.model, 'change', (model, options) => {
                    if (!chart.model.get('rangeMaximum')) {
                        chart.filter(null);
                    } else if (model.get('rangeMinimum') === undefined || model.get('rangeMinimum') === null) {
                        model.set('rangeMinimum', 0);
                    } else if (model.get('rangeMaximum') < model.get('rangeMinimum')) {
                        if (model.changedAttributes().rangeMaximum) {
                            model.set('rangeMaximum', model.get('rangeMinimum'));
                        } else {
                            model.set('rangeMinimum', model.get('rangeMaximum'));
                        }
                    } else {
                        chart.replaceFilter(
                            dc.filters.RangedFilter(chart.model.get('rangeMinimum'), chart.model.get('rangeMaximum'))
                        );
                        dc.redrawAll();
                    }
                });
                chart.on('filtered.base', (chart, filter) => {
                    if (filter) {
                        chart.model.set({
                            rangeMinimum: Math.round(filter[0]),
                            rangeMaximum: Math.round(filter[1])
                        });
                    } else {
                        chart.model.set({
                            rangeMinimum: undefined,
                            rangeMaximum: undefined
                        });
                    }
                });
                this.$(el).after(
                    /*template*/ `<div class="dc-histogram__manual dc-histogram__manual-${chart.model.cid}"></div> `
                );
                this.createAndRenderSubView(`.dc-histogram__manual-${chart.model.cid}`, ManualRangeInputs, {
                    model: chart.model
                });
            }

            return chart;
        },

        buildRowChart: function(el, dimension, group, opts) {
            const self = this;
            const width = self.$(el).width();
            const chart = dc.rowChart(el, self.group);
            const options = _.extend(
                {},
                {
                    applyInitialFilter: function() {}
                },
                opts
            );

            const barHeight = options.barHeight || 15;
            const rowGap = options.rowGap || 3;
            chart
                .margins(
                    options.margins || {
                        top: 35,
                        right: 5,
                        bottom: 20,
                        left: 5
                    }
                )
                .width(width)
                .height(function() {
                    const axisHeight = 45;
                    return group.size() * (barHeight + rowGap) + axisHeight;
                })
                .dimension(dimension)
                .group(group)
                .gap(rowGap)
                .x(d3.scaleLinear())
                .elasticX(true)
                .turnOnControls(true)
                .controlsUseVisibility(true)
                .fixedBarHeight(barHeight)
                .xAxis(d3.axisTop());

            chart.on('pretransition', function() {
                chart.select('g.axis').attr('transform', 'translate(0,0)');
                chart.selectAll('line.grid-line').attr('y2', chart.effectiveHeight());
            });
            self.$(chart.anchor()).addClass('row-chart');

            if (options.xAxisLabel) {
                chart.on('renderlet', function(chart) {
                    chart
                        .svg()
                        .append('text')
                        .attr('class', 'x-axis-label')
                        .attr('text-anchor', 'middle')
                        .attr('x', chart.width() / 2)
                        .attr('y', 15)
                        .text(options.xAxisLabel);
                });
            }

            chart.applyInitialFilter = options.applyInitialFilter.bind(chart);
            return chart;
        },

        buildPieChart: function(el, dimension, group, dcOptions = {}) {
            const self = this;
            const width = self.$(el).width();
            const chart = dc.pieChart(el, self.group);

            const slicesCap = 3;
            chart
                .width(width)
                .slicesCap(slicesCap)
                .innerRadius(width / 8)
                .dimension(dimension)
                .turnOnControls(true)
                .controlsUseVisibility(true)
                .group(group)
                .legend(dc.legend())
                // workaround for #703: not enough data is accessible through .label() to display percentages
                .on('pretransition', function(chart) {
                    chart.selectAll('text.pie-slice').text(function(d) {
                        return `${
                            d.data.key
                        } ${dc.utils.printSingleValue(((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100)}%`;
                    });
                });
            self.$(chart.anchor()).addClass('pie-chart');
            chart.applyInitialFilter = dcOptions.applyInitialFilter.bind(chart);

            return chart;
        },

        _removeEmptyBins: function(source_group) {
            return {
                all: function() {
                    return source_group.all().filter(function(d) {
                        return d.value !== 0;
                    });
                }
            };
        },

        _nonzero_min: function(chart) {
            dc.override(chart, 'yAxisMin', function() {
                const min = d3.min(chart.data(), function(layer) {
                    return d3.min(layer.values, function(p) {
                        return p.y + p.y0;
                    });
                });
                return dc.utils.subtract(min, chart.yAxisPadding());
            });
            return chart;
        },

        truncateMiddle: function(text, maxLength) {
            if (!_.isUndefined(text)) {
                text = text.toString();

                if (text.length <= maxLength) {
                    return text;
                }
                const halveLength = (maxLength - 3) / 2;
                return `${text.substr(0, halveLength)}...${text.substr(text.length - halveLength, text.length)}`;
            }
            return text;
        },

        buildHeatmap: function(el, dimension, group, opts) {
            const self = this;

            const chart = dc.heatMap(el, self.group);
            const options = _.extend(
                {},
                {
                    applyInitialFilter: function() {},
                    boxSize: 45,
                    xlabelWidth: 250,
                    ylabelWidth: 250,
                    rowItemLength: 10,
                    columnItemLength: 10,
                    rotateXTextAngle: undefined
                },
                opts
            );

            const filtered_group = self._removeEmptyBins(group);
            chart
                .width(options.rowItemLength * options.boxSize + options.ylabelWidth)
                .height(options.columnItemLength * options.boxSize + options.xlabelWidth)
                .dimension(dimension)
                .group(filtered_group);

            self.$(chart.anchor()).addClass('heatmap-chart');

            chart.margins().top = 5;
            chart.margins().left = options.ylabelWidth;
            chart.margins().right = options.xlabelWidth;
            chart.margins().bottom = options.xlabelWidth;

            if (options.rotateXTextAngle) {
                let hasRendered = false;
                chart.on('pretransition', function(_chart) {
                    if (!hasRendered) {
                        return;
                    }
                    _chart
                        .selectAll('g.cols.axis text')
                        .transition()
                        .duration(_chart.transitionDuration())
                        .style('fill', '#ffffff');
                });
                _.extend(
                    options,
                    {
                        field: 'uniques'
                    },
                    opts
                );
                chart.on('renderlet', function(_chart) {
                    if (!self.hasValue(_chart.data())) {
                        return;
                    }

                    // Make room for the left (Y) axis labels and the top (X) axis accounting for the diaganol
                    _chart
                        .selectAll('.heatmap')
                        .attr('transform', `translate(${options.ylabelWidth},${options.xlabelWidth * 0.75})`);

                    _chart
                        .selectAll('g.cols.axis text')
                        .style('text-anchor', 'middle')
                        .attr('y', -15)
                        .attr('transform', function() {
                            const coord = this.getBBox();
                            const x = coord.x + coord.width / 2,
                                y = coord.y + coord.height / 2;
                            return `rotate(${options.rotateXTextAngle} ${x} ${y})`;
                        })
                        .style('text-anchor', 'unset');

                    if (hasRendered) {
                        _chart
                            .selectAll('g.cols.axis text')
                            .transition()
                            .duration(_chart.transitionDuration())
                            .style('fill', '#0000000');
                    }
                    hasRendered = true;
                });
            }
            return chart;
        },

        buildLineChart: function(el, dimension, group, opts) {
            const self = this;
            const width = self.$(el).width();
            const chart = self._nonzero_min(dc.lotameLineChart(el, self.group));
            const options = _.extend(
                {
                    applyInitialFilter: function() {},
                    renderArea: true,
                    showTooltip: false,
                    renderDataPoints: false,
                    turnOnControls: false,
                    chartClass: 'line-chart',
                    groupName: '',
                    yAxisPadding: '',
                    margins: {
                        top: 35,
                        right: 5,
                        bottom: 20,
                        left: 5
                    },
                    lastDataDate: null
                },
                opts
            );

            chart
                .margins(options.margins)
                .width(width)
                .dimension(dimension)
                .group(group, options.groupName)
                .brushOn(false)
                .x(d3.scaleLinear())
                .renderArea(options.renderArea)
                .renderDataPoints(options.renderDataPoints)
                .elasticY(true)
                .elasticX(true)
                .clipPadding(0)
                .yAxisPadding('25%')
                .options({
                    turnOnControls: options.turnOnControls
                })
                .controlsUseVisibility(true)
                .withLastDataDate(options.lastDataDate);

            self.$(chart.anchor()).addClass(options.chartClass);
            self.renderCustomBrushes(chart, options.handleHeight, options.useCircleHandles);

            chart.applyInitialFilter = options.applyInitialFilter.bind(chart);

            if (options.showTooltip) {
                const tooltip = d3.select('.base-tooltip').node()
                    ? d3.select('.base-tooltip')
                    : d3
                          .select('body')
                          .append('div')
                          .attr('class', 'base-tooltip');

                const mainChart = _.isString(el) ? chart : el;
                mainChart.on('pretransition.add-tip', function(chart) {
                    chart
                        .selectAll('circle.dot')
                        .on('mouseover', function(d) {
                            const parentElement = d3
                                .select(d3.event.currentTarget)
                                .node()
                                .getBoundingClientRect();

                            const content = options.getTooltip.apply(self, [d]);
                            tooltip.style('display', 'inline-block').html(content);

                            const x = parentElement.x + parentElement.width / 2;
                            const left = x - tooltip.node().getBoundingClientRect().width / 2;
                            const top =
                                parentElement.top - tooltip.node().getBoundingClientRect().height + window.scrollY - 10;
                            tooltip.style('left', `${left}px`);
                            tooltip.style('top', `${top}px`);
                        })
                        .on('mouseout', function() {
                            tooltip.style('display', 'none');
                        });
                });
            }
            return chart;
        },

        prepElement: function(el, name, state) {
            const self = this;

            self.$(el).html(
                this.baseTemplate({
                    name: name,
                    state: state || 'loaded'
                })
            );
        },

        renderSubviewDC: function(el, ViewClass, options, dcOpts) {
            const self = this;

            const dcOptions = _.extend(
                {
                    applyInitialFilter: undefined, //function() {},
                    prepData: function(chart) {
                        // Override this with view specific data setup
                    }
                },
                dcOpts
            );
            const _chart = dc
                .baseMixin({})
                .dimension(dcOptions.dimension)
                .group(dcOptions.group);

            const view = self.renderSubView(
                el,
                ViewClass.extend({
                    getChart: function() {
                        return this._chart;
                    }
                }),
                _.extend(options, {
                    _chart: _chart
                })
            );

            _chart.onChange = function(val) {
                if (val && _.isArray(val)) {
                    _chart.replaceFilter([val]);
                } else if (val) {
                    _chart.replaceFilter(val);
                } else {
                    _chart.filterAll();
                }
                dc.events.trigger(function() {
                    _chart.redrawGroup();
                });
            };

            _chart._doRedraw = function() {
                dcOptions.prepData.apply(view, [_chart]);
                view.render();
            };

            _chart.hasFilter = function() {
                return true;
            };

            _chart.filterAll = function() {
                this.filter(null);
                this._doRedraw();
            };
            if (_.isFunction(dcOptions.applyInitialFilter)) {
                _chart.applyInitialFilter = dcOptions.applyInitialFilter.bind(_chart);
            }
            view.getChart = function() {
                return _chart;
            };
            dc.registerChart(_chart, self.group);
            return view;
        },

        applyDefaultFilters: function(chart) {
            const self = this;
            if (_.isFunction(chart.applyInitialFilter)) {
                chart.filterAll(self.group);
                chart.applyInitialFilter();
            }
        },

        applyAllDefaultFilters: function() {
            const self = this;
            dc.chartRegistry.list(self.group).forEach(self.applyDefaultFilters);
            dc.redrawAll(self.group);
        },

        // Looks at a supplied data set containing dates, and pads the set with elements
        // with 0 values for the dates where no data is present.
        padDatesInMonth: function(dataList, type) {
            if (dataList && dataList.length > 0) {
                const firstSuppliedDate = moment(dataList[0]['dataDate']);
                let dataDateInt = parseInt(`${firstSuppliedDate.format('YYYYMM')}00`, 10);
                const daysInMonth = firstSuppliedDate.daysInMonth();
                const parseTime = d3.timeParse('%Y%m%d');
                const updatedList = [];
                for (let x = 0; x < daysInMonth; x++) {
                    dataDateInt++;
                    const dataDate = parseTime(dataDateInt);
                    updatedList.push(
                        dataList.find(item => {
                            return parseInt(moment(item.dataDate).format('YYYYMMDD'), 10) === dataDateInt;
                        }) || {
                            dataDate: dataDate,
                            value: 0,
                            type: type
                        }
                    );
                }
                return updatedList;
            }
            return dataList;
        }
    });

    // Ensure the events and defaults extend to all children
    baseChart.extend = function(child) {
        const view = BaseView.extend.apply(this, arguments);
        view.prototype.events = _.extend({}, this.prototype.events, child.events);
        view.prototype.defaults = function() {
            return _.extend({}, _.result(this.prototype, 'defaults'), _.result(child, 'defaults'));
        };
        return view;
    };

    return baseChart;
});
