define(function(require) {
    const BaseView = require('app/Base.view');
    const _ = require('underscore');
    const InputMask = require('inputmask').default;
    require('components/TextInput.css');

    return BaseView.extend({
        defaults: {
            model: undefined
        },

        initialize: function(options) {
            _.extend(this, this.defaults, options);
        },

        render: function() {
            const template = _.template(/* template */ `
            <div class="text-input">
                <input inputmode="numeric" class="dc-histogram__min text-input__input" data-inputmask="'alias': 'integer', 'groupSeparator': ',', 'autoUnmask': true"/>
            </div>
            <label class="dc-histogram__x-axis-label"></label>
            <div class="text-input">
                <input inputmode="numeric" class="dc-histogram__max text-input__input" data-inputmask="'alias': 'integer', 'groupSeparator': ',', 'autoUnmask': true"/>
            </div>`);

            this.$el.html(template({}));

            const config = {
                '.dc-histogram__min': {
                    observe: 'rangeMinimum',
                    updateView: true,
                    updateModel: true
                },
                '.dc-histogram__x-axis-label': {
                    observe: 'label',
                    updateView: true
                },
                '.dc-histogram__max': {
                    observe: 'rangeMaximum',
                    updateView: true,
                    updateModel: true
                }
            };
            this.stickit(this.model, config);
            InputMask().mask(document.querySelectorAll('.text-input__input'));
        }
    });
});
